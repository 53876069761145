<template>
  <div>
    <!-- TODO -->
    <div class="row">
      <div class="content-center" style="cursor: pointer">
        <img
          :src="logoImg"
          @click="changeTitle({ key: 'home' })"
          class="img-Logo"
        />
        <p @click="govideoClass">视频学堂</p>
      </div>
      <div class="content-right"></div>
    </div>
    <!-- <div>
      <homeComAbout :src="bgImg"></homeComAbout>
    </div> -->
    <div class="banner">
      <div class="banner-container">
        <div class="banner-left">
          <div class="left-text">
            <div class="title">SMARTCAT嘿猫嗨购</div>
            <div class="title">全球集运建站平台</div>
            <div class="smallTitle">新集运 新机遇 新商机</div>
            <div class="botBtn">
              <button class="btn" @click="showContact">
                <span class="text">免费建站</span>
              </button>
            </div>
          </div>
        </div>
        <div class="banner-right">
          <div class="pic">
            <img class="img" :src="bgImg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- TODO 搜索栏 -->
    <div
      class="searchBox"
      style="display: flex; justify-content: space-between"
    >
      <el-input
        v-model="input"
        prefix-icon="el-icon-search"
        placeholder="请输入内容"
        @change="goclassroomSearch"
      ></el-input>
    </div>
    <!-- TODO 骨架图 -->
    <div class="skeleton-wrapper" v-if="dataLoaded">
      <div
        class="skeleton-wrapper"
        style="width: 1200px; margin: 0 auto; height: auto"
      >
        <el-skeleton style="width: 100%; margin-top: 50px">
          <template slot="template">
            <div style="display: flex; justify-content: space-between">
              <div v-for="index in 4" :key="index" style="">
                <el-skeleton-item
                  variant="image"
                  style="width: 240px; height: 240px"
                />
                <div style="padding: 14px">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-items: space-between;
                    "
                  >
                    <el-skeleton-item
                      variant="text"
                      style="margin-right: 16px"
                    />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
        <el-skeleton style="width: 100%; margin-top: 50px">
          <template slot="template">
            <div style="display: flex; justify-content: space-between">
              <div v-for="index in 4" :key="index" style="">
                <el-skeleton-item
                  variant="image"
                  style="width: 240px; height: 240px"
                />
                <div style="padding: 14px">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-items: space-between;
                    "
                  >
                    <el-skeleton-item
                      variant="text"
                      style="margin-right: 16px"
                    />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
        <el-skeleton style="width: 100%; margin-top: 50px">
          <template slot="template">
            <div style="display: flex; justify-content: space-between">
              <div v-for="index in 4" :key="index" style="">
                <el-skeleton-item
                  variant="image"
                  style="width: 240px; height: 240px"
                />
                <div style="padding: 14px">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-items: space-between;
                    "
                  >
                    <el-skeleton-item
                      variant="text"
                      style="margin-right: 16px"
                    />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
    </div>
    <!-- TODO 课程部分 -->
    <div class="videoClass" v-else>
      <div class="videoClass_cont">
        <!-- TODO 精选课程 -->
        <div
          class="custom-carousel"
          v-if="videoListes[0].lessons_info.length > 0"
        >
          <div class="videoClass_cont_title">
            <p class="left_title" @click="reservedState = true">
              {{ videoListes ? videoListes[0].name : "" }}
            </p>
            <div class="btnes">
              <button
                @click="prev('1', null, null)"
                id="custom-prev"
                :disabled="currentIndex <= 0"
              >
                <i
                  class="el-icon-arrow-left"
                  :style="{
                    color: currentIndex <= 0 ? 'rgba(204, 204, 204, 1)' : '',
                  }"
                ></i>
              </button>
              <p class="cutNum">
                {{ currentIndex + 1 }}/{{
                  videoListes[0].lessons_info.length % 4 === 0
                    ? videoListes[0].lessons_info.length / 4
                    : Math.floor(videoListes[0].lessons_info.length / 4) + 1
                }}
              </p>
              <button
                @click="next('1', videoListes[0].lessons_info, null, null)"
                id="custom-next"
                :disabled="
                  currentIndex >=
                  Math.ceil(videoListes[0].lessons_info.length / 4) - 1
                "
              >
                <i
                  class="el-icon-arrow-right"
                  :style="{
                    color:
                      currentIndex >=
                      Math.ceil(videoListes[0].lessons_info.length / 4) - 1
                        ? 'rgba(204, 204, 204, 1)'
                        : '',
                  }"
                ></i>
              </button>
            </div>
          </div>
          <el-carousel
            trigger="click"
            arrow="never"
            height="270px"
            ref="carousel"
            indicator-position="none"
            :autoplay="false"
          >
            <el-carousel-item
              v-for="(group, index) in groupedData(videoListes[0].lessons_info)"
              :key="index"
            >
              <div
                v-for="item in group"
                :key="item.id"
                class="curriculum_ndividual"
              >
                <img
                  :src="item.cover_images"
                  style="cursor: pointer; object-fit: cover"
                  alt=""
                  @click="goclassDetail(videoListes[0].id, item.id)"
                />
                <p class="curriculum_ndividual_title" :title="item.title">
                  {{ item.title }}
                </p>
                <div class="curriculum_ndividual_btn">
                  <!-- <p v-if="item.type == 1">照片</p>
                  <p v-else-if="item.type == 2">视频</p>
                  <p v-else-if="item.type == 3">文档</p>
                  <p>|</p> -->
                  <p class="peoSty">
                    {{ item.click_number + item.click_offset }}人学过
                  </p>
                  <p
                    class="stBtn"
                    style="cursor: pointer"
                    @click="goclassDetail(videoListes[0].id, item.id)"
                  >
                    立即学习
                  </p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- TODO  快速了解集运-->
        <div
          class="custom-carousel"
          v-for="(item, index) in videoListesnext"
          :key="index"
          v-if="item.lessons_info.length > 0"
        >
          <div class="videoClass_cont_title">
            <p class="left_title">{{ item.name }}</p>
            <div class="btnes">
              <button
                @click="prev('2', item.id, item.refName)"
                id="custom-prev"
                :disabled="item.num <= 0"
              >
                <i
                  class="el-icon-arrow-left"
                  :style="{
                    color: item.num <= 0 ? 'rgba(204, 204, 204, 1)' : '',
                  }"
                ></i>
              </button>
              <p class="cutNum">
                {{ item.num + 1 }}/{{
                  item.lessons_info.length % 4 === 0
                    ? item.lessons_info.length / 4
                    : Math.floor(item.lessons_info.length / 4) + 1
                }}
              </p>
              <button
                @click="next('2', item.lessons_info, item.id, item.refName)"
                id="custom-next"
                :disabled="
                  item.num >= Math.ceil(item.lessons_info.length / 4) - 1
                "
              >
                <i
                  class="el-icon-arrow-right"
                  :style="{
                    color:
                      item.num >= Math.ceil(item.lessons_info.length / 4) - 1
                        ? 'rgba(204, 204, 204, 1)'
                        : '',
                  }"
                ></i>
              </button>
            </div>
          </div>
          <el-carousel
            trigger="click"
            arrow="never"
            height="280px"
            :ref="item.refName"
            indicator-position="none"
            :autoplay="false"
          >
            <el-carousel-item
              v-for="(group, index) in groupedData(item.lessons_info)"
              :key="index"
            >
              <div
                v-for="itemes in group"
                :key="itemes.id"
                class="curriculum_ndividual"
              >
                <img
                  :src="itemes.cover_images"
                  style="cursor: pointer; object-fit: cover"
                  alt=""
                  @click="goclassDetail(item.id, itemes.id)"
                />
                <p class="curriculum_ndividual_title" :title="itemes.title">
                  {{ itemes.title }}
                </p>
                <div class="curriculum_ndividual_btn">
                  <!-- <p v-if="itemes.type == 1">照片</p>
                  <p v-else-if="itemes.type == 2">视频</p>
                  <p v-else-if="itemes.type == 3">文档</p>
                  <p>|</p> -->
                  <p class="peoSty">
                    {{ itemes.click_number + itemes.click_offset }}人学过
                  </p>
                  <p
                    class="stBtn"
                    style="cursor: pointer"
                    @click="goclassDetail(item.id, itemes.id)"
                  >
                    立即学习
                  </p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- TODO 留资弹窗 -->
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(176, 176, 176, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      "
      v-if="reservedState"
    >
      <div class="reserved_box">
        <div class="reserved_tit">
          <p style="font-weight: bold; font-size: 20px">申请免费观看权益</p>
          <i
            style="
              font-size: 20px;
              font-weight: bold;
              color: rgba(40, 47, 54, 0.3);
              cursor: pointer;
            "
            @click="exitOut"
            class="el-icon-close"
          ></i>
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon1.png" alt="" />
          <p></p>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.name"
            placeholder="请填写您的姓名"
          />
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon2.png" alt="" />
          <p></p>
          <a-select
            v-model="reservedData.country"
            show-search
            placeholder="选择区号"
            dropdownClassName="drop"
            class="login-select"
          >
            <a-icon
              style="margin-top: -2px"
              slot="suffixIcon"
              type="caret-down"
              :style="{ fontSize: '16px' }"
            />
            <a-select-option
              v-for="item in countryes"
              :key="item.id"
              :label="'+' + item.timezone"
              :value="item.timezone"
            >
              +{{ item.timezone }}
            </a-select-option>
          </a-select>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.mobile"
            placeholder="请填写您的手机号"
          />
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon3.png" alt="" />
          <p></p>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.wechat"
            placeholder="请填写您的微信号"
          />
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon4.png" alt="" />
          <p></p>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.email"
            placeholder="请填写您的邮箱（选填）"
          />
        </div>
        <p class="subBtn" style="cursor: pointer" @click="outReserved">
          确认提交
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartcatplusIndex",
  data() {
    return {
      logoImg: require("@/assets/smartcat/logo.svg"),
      bgImg: require("@/assets/smartcat/bannerAbout.png"),
      bgImges: require("@/assets/sexs.png"),
      input: "",
      videoUrl:
        "https://beego-study-1252088954.cos.ap-hongkong.myqcloud.com/resources/20231201/1701400788_sZ2UPPEq7wHcNea8.mov",
      videoSource: [
        {
          src: "https://beego-study-1252088954.cos.ap-hongkong.myqcloud.com/resources/20231201/1701398108_BhrNhbBjfrofeq2c.mp4",
          resolution: 360,
        },
        {
          src: "https://beego-study-1252088954.cos.ap-hongkong.myqcloud.com/resources/20231201/1701398108_BhrNhbBjfrofeq2c.mp4",
          resolution: 720,
        },
        {
          src: "https://beego-study-1252088954.cos.ap-hongkong.myqcloud.com/resources/20231201/1701398108_BhrNhbBjfrofeq2c.mp4",
          resolution: 1080,
        },
      ],
      cover: "https://img1.wxzxzj.com/vpc-example-cover-your-name-c.png",
      currentIndex: 0,
      knowIndex: 0,
      videoListes: [
        {
          name: "",
          lessons_info: [],
        },
      ],
      videoListesnext: [],
      dataLoaded: true,
      countryes: [
        {
          value: "",
          label: "",
        },
      ],

      reservedState: false,
      reservedData: {
        name: "",
        country: undefined,
        mobile: "",
        wechat: "",
        email: "",
      },
    };
  },
  computed: {},
  mounted() {},
  created() {
    document.title = "视频学堂";
    this.videoList();
    this.getCountry();
    setTimeout(() => {
      this.dataLoaded = false;
    }, 2000);
  },
  // watch: {
  //   reservedState: function (newVal, oldVal) {
  //     if (newVal == true) {
  //       window.addEventListener("wheel", this.handleStopWheel, {
  //         passive: false,
  //       });
  //     } else {
  //       window.removeEventListener("wheel", this.handleStopWheel);
  //     }
  //     deep: true
  //   },
  // },
  methods: {
    // 提交留资信息
    outReserved() {
      let msg = "";
      if (!this.reservedData.name) {
        msg = "请输入姓名";
      } else if (!this.reservedData.country) {
        msg = "请输入区号";
      } else if (!this.reservedData.mobile) {
        msg = "请输入手机号";
      } else if (!this.reservedData.wechat) {
        msg = "请输入微信号";
      }
      if (msg) {
        this.$message.warning(msg);

        return;
      }

      const params = {
        name: this.reservedData.name,
        country: this.reservedData.country,
        mobile: this.reservedData.mobile,
        wechat: this.reservedData.wechat,
        email: this.reservedData.email,
      };

      this.$request.getoutReserved(params).then((res) => {
        if (res.ret) {
          this.$message.success("提交成功");
          this.reservedState = false;
          window.location.reload();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    exitOut() {
      this.reservedState = false;
    },
    //区号
    getCountry() {
      this.$request.country().then((res) => {
        if (res.ret) {
          this.countryes = res.data;
        }
      });
    },
    // expand(event) {
    //   event.target.style.transform = "scale(1.2)";
    // },
    // shrink(event) {
    //   event.target.style.transform = "scale(1)";
    // },
    govideoClass() {
      this.$router.push({
        path: "/videoClass",
      });
    },
    showContact() {
      this.$router.push({
        path: "/login",
      });
    },
    changeTitle(item) {
      this.$router.push(item.key);
    },
    generateRandomString(length) {
      const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    videoList() {
      const _this = this;
      this.$request.videoClassroom(2).then((res) => {
        if (res.ret) {
          _this.videoListes = res.data.star;
          _this.videoListesnext = res.data.columns.reverse().map((item) => {
            const uniqueId = this.generateRandomString(5);
            return { ...item, num: 0, refName: uniqueId };
          });
        }
      });
    },
    goclassroomSearch() {
      this.$router.push({
        path: "/classroomSearch",
        query: {
          value: this.input,
        },
      });
      this.input = "";
      // window.open(routeData.href, "_blank");
    },
    handleStopWheel(e) {
      e.preventDefault();
    },
    goclassDetail(column_id, lessons_id) {
      this.$router.push({
            path: "/classDetail",
            query: {
              column_id,
              lessons_id,
            },
          });
      // this.$request.getTemporaryurl(lessons_id).then((res) => {
      //   if (res.ret && res.data.is_play == true) {
      //     this.$router.push({
      //       path: "/classDetail",
      //       query: {
      //         column_id,
      //         lessons_id,
      //       },
      //     });
      //   } else {
      //     this.reservedState = true;
      //   }
      // });

      // window.open(routeData.href, "_blank");
    },
    groupedData(type) {
      const result = [];
      for (let i = 0; i < type.length; i += 4) {
        result.push(type.slice(i, i + 4));
      }
      return result;
    },
    prev(type, se, pr) {
      if (type == 1) {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
        this.$refs.carousel && this.$refs.carousel.prev();
      } else {
        const currentItem = this.videoListesnext.find((item) => item.id == se);
        if (currentItem && currentItem.num > 0) {
          this.$set(currentItem, "num", currentItem.num - 1);
        }
        this.$refs[pr][0] && this.$refs[pr][0].prev();
      }
    },
    next(type, el, se, pr) {
      if (type == 1) {
        if (this.currentIndex < Math.ceil(el.length / 4) - 1) {
          this.currentIndex++;
        }
        this.$refs.carousel && this.$refs.carousel.next();
      } else {
        const currentItem = this.videoListesnext.find((item) => item.id === se);
        if (currentItem && currentItem.num < Math.ceil(el.length / 4) - 1) {
          this.$set(currentItem, "num", currentItem.num + 1);
        }
        this.$refs[pr][0] && this.$refs[pr][0].next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
 /deep/.ant-select-selection__placeholder{
  margin-top: -8px;
 }
/deep/.ant-select-selection {
  border-color: #f8f8f8;
  border-color: rgba(238, 238, 238, 1);
}
/deep/.ant-select-selection {
  // border: none !important;
  box-shadow: none;
  outline: none;
}
/deep/#custom-prev {
  z-index: 99 !important;
}
/deep/#custom-next {
  z-index: 99 !important;
}
.is-animating {
  transition: transform 0.5s ease-in-out;
}
.reserved_box {
  width: 400px;
  height: auto;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 30px;
}
.reserved_tit {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.reserved_everyone {
  display: flex;
  border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 11px 10px;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  /deep/.ant-select-selection {
    width: 100px;
    border: none;
    outline: none;
  }
  p {
    margin: 0;
    margin-left: 14px;
    width: 1px;
    height: 15px;
    background-color: rgba(51, 51, 51, 0.1);
  }
  img {
    width: 20px;
    color: rgba(40, 47, 54, 0.3);
  }
  /deep/.el-input__inner {
    width: 100px;
    height: 36px;
    // border-radius: 20px 0px 0 20px;
    background-color: #fff;
    padding-top: 2px;
    border: none;
    outline: none;
  }
  // border-right: 0;
  input::-webkit-input-placeholder {
    color: #ccc;
  }
}
.reserved_everyone:nth-child(3) {
  padding: 5px 10px;
}
.subBtn {
  width: 136px;
  height: 40px;
  border-radius: 20px;
  background: rgba(255, 208, 0, 1);
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  margin-top: 40px;
}
.banner {
  background-color: #f8f8f8;
  .expandable {
    transition: box-shadow 0.3s, transform 0.3s;
  }
  .banner-container {
    display: flex;
    width: 1220px;
    margin: auto;
    position: relative;

    .banner-left {
      position: absolute;
      z-index: 999;

      .left-text {
        margin-top: 80px;

        .title {
          font-size: 60px;
          letter-spacing: 0px;
          font-weight: bold;
          line-height: 100px;
          color: rgba(0, 0, 0, 1);
          font-family: PingFangSC, sans-serif;
        }

        .smallTitle {
          margin-top: 20px;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0px;
          line-height: 39.2px;
          color: rgba(0, 0, 0, 1);
          font-family: PingFangSC-Bold, sans-serif;
        }

        .botBtn {
          margin-top: 50px;
          .btn {
            width: 160px;
            height: 44px;
            border-radius: 22px;
            background-color: #fdd000;
            border-color: #fdd000 !important;
            border: none;
            cursor: pointer;

            .text {
              font-family: PingFangSC-Regular, sans-serif;
              font-size: 18px;
              font-weight: 500;
              color: rgba(0, 0, 0, 1);
              text-align: center;
            }
          }
          .btn:hover {
            background: #000;
            .text {
              color: #fff;
            }
          }
        }
      }
    }

    .banner-right {
      width: 750px;
      height: 500px;

      .pic {
        .img {
          width: 750px;
          height: 500px;
          margin-left: 450px;
        }
      }
    }
  }
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    .dialog-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 400px;
      background-color: #fff;
      border-radius: 12px;
      background: linear-gradient(to top, #fff, #fff5cb);

      .topImg {
        margin-top: -70px;
        .bgImg {
          width: 268px;
          height: 126px;
        }
      }

      .contact {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;
        background-color: #fff5c9;
        border-radius: 15px;
        margin-top: 40px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        .contactImg {
          padding: 4px;
          background-color: #fff;
          width: 140px;
          height: 140px;
          border-radius: 8px;
        }
      }
      .text {
        margin-top: 30px;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 25.2px;
        color: rgba(102, 102, 102, 1);
      }
    }
  }
  .dialog-footer {
    text-align: right;
    margin-top: 20px;

    .botBtn {
      width: 40px;
      height: 40px;
      border: 1px solid #fff;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      background-color: transparent;
    }
  }
}
.row {
  width: 1200px;
  margin: auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  line-height: 64px;
}
.content-center {
  display: flex;
  align-items: center;
  .img-Logo {
    width: 146px;
    height: 40px;
  }
  p {
    width: 80px;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    color: rgba(0, 0, 0, 1);
    margin-left: 30px;
  }
}
.videoClass {
  min-height: 100vh;
  height: auto;
  margin-top: 10px;
  display: flex;
  background: #f8f8f8;
  border-radius: 10px;
  position: relative;

  .videoClass_cont {
    width: 1220px;
    height: auto;
    margin: 0 auto;
    padding-bottom: 100px;
  }
  .videoClass_cont_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 43px;
    .btnes {
      display: flex;
      align-items: baseline;
      .cutNum {
        width: 24px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        margin: 0 10px;
        line-height: 20px;
      }
      #custom-prev,
      #custom-next {
        width: 24px !important;
        height: 24px !important;
        opacity: 1;
        border-radius: 2px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: rgba(0, 0, 0, 1);
        z-index: 99999;
      }
    }
  }
  .left_title {
    font-size: 24px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
  }
  .custom-carousel button {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .el-carousel__item {
    // display: flex;
    // justify-content: space-between;
    // margin: 0 5px;
    padding: 0 10px;
    .curriculum_ndividual {
      float: left;
      width: 285px;
      height: 260px;
      margin-right: 20px;
      box-sizing: border-box;
      opacity: 1;
      margin-top: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 1);
      transition: transform 0.3s; /* 添加过渡效果 */
      // margin-right: 20px;
      img {
        width: 285px;
        height: 160px;
        border-radius: 10px 10px 0px 0px;
      }
      .curriculum_ndividual_title {
        height: 23px;
        padding: 0 20px;
        margin-top: 14px;
        font-size: 16px;
        font-weight: bold;
        line-height: 22.4px;
        color: rgba(0, 0, 0, 1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .curriculum_ndividual_btn {
        height: 30px;
        margin-top: 13px;
        padding: 0 20px;
        font-size: 14px;
        display: flex;
        align-items: baseline;
        line-height: 30px;
        position: relative;
        // p:nth-child(1) {
        //   color: rgba(136, 136, 136, 1);
        //   width: 29px;
        // }
        // p:nth-child(2) {
        //   color: rgba(136, 136, 136, 1);
        //   margin-left: 10px;
        // }
        .peoSty {
          width: 60px;
          color: rgba(136, 136, 136, 1);
          // margin-left: 10px;
          flex: 1;
        }
        .stBtn {
          width: 80px;
          height: 30px;
          opacity: 1;
          border-radius: 5px;
          background: rgba(255, 208, 0, 1);
          text-align: center;
          line-height: 30px;
          position: absolute;
          right: 20px;
        }
      }
    }
    .curriculum_ndividual:hover {
      border-radius: 10px;
      // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
    .curriculum_ndividual:nth-child(4) {
      margin-right: 0;
    }
  }
  /deep/.el-carousel__container {
  }
}
.searchBox {
  margin: auto;
  width: 1200px;
  margin-top: 10px;

  .el-input {
    width: 600px;
    height: 36px;
    opacity: 1;
  }
  /deep/.el-input__inner {
    border-radius: 20px !important;
    background: rgba(248, 248, 248, 1);
    border: none;
    height: 36px;
    padding-left: 50px;
  }
  /deep/.el-input__inner:focus,
  /deep/.el-input__inner:hover {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  /deep/.el-input__icon {
    margin-left: 20px;
  }
}
</style>
<style >
ul>.ant-select-dropdown-menu-item-active {
  background: #eee !important;
}
</style>
